import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import Preloader from '../shared/preloader';
import userGroupsQuery from '../requests/queries/userGroups';
import recentContentQuery from '../requests/queries/recentArticles';
import { isMobile } from 'plaza-react-components/src/helpers/hooks';
import popularContentQuery from '../requests/queries/popularArticles';
import { addGoogleStructuredData } from '../lib/googleStructuredData';
import ChevronDown from 'plaza-react-components/src/shared/assets/icons/redesign/chevron-down.svg';

const Button = dynamic(
  () => import('plaza-react-components/src/components/redesign/Button/Button')
);
const LoginBox = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/LoginBox/LoginBox'
    )
);
const TopicList = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/TopicList/TopicList'
    )
);
const ContentVerticalList = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/ContentVerticalList/ContentVerticalList'
    )
);
const IntroBox = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/IntroBox/IntroBox.jsx'
    )
);
const ContentFeed = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/ContentFeed/ContentFeed.jsx'
    )
);
const CoursesBox = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/CoursesBox/CoursesBox.jsx'
    )
);
const CommunityBox = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/CommunityBox/CommunityBox.jsx'
    )
);
const Calendar = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/home/Calendar/Calendar.jsx'
    )
);

export async function getServerSideProps(ctx) {
  return {
    props: {
      isMobile: isMobile(ctx)
    }
  };
}

export default function IndexPage({ config, user, isMobile }) {
  const [loadedList, setLoadedList] = useState();
  const [contentLoading, setContentLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const {
    data: recentContentData,
    loading: recentContentLoading,
    fetchMore
  } = useQuery(recentContentQuery, {
    variables: {
      limit: 10,
      offset: 0
    }
  });

  const { data: popularContentData, loading: popularContentLoading } = useQuery(
    popularContentQuery,
    {
      variables: {
        limit: 7,
        offset: 0
      }
    }
  );

  const { data: userGroupsData } = useQuery(userGroupsQuery, {
    skip: !scrolled
  });

  useEffect(() => {
    if (!location.search || location.search.startsWith('?jwt'))
      history.replaceState({}, '', '/'), [];
  });

  useEffect(() => {
    document.addEventListener('scroll', () => !scrolled && setScrolled(true));

    addGoogleStructuredData({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: 'https://www.zwangerenportaal.nl/',
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: 'https://www.zwangerenportaal.nl/search?query={query}'
        },
        'query-input': 'required name=query'
      }
    });
  }, []);

  return (
    <>
      {!user.username && <IntroBox isMobile={isMobile} />}

      {recentContentLoading || popularContentLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="grid home-page-content">
            {!!recentContentData?.recentContent && (
              <ContentFeed
                highlightFirstItem
                title="Uitgelichte artikelen"
                list={recentContentData.recentContent.slice(
                  0,
                  isMobile ? 6 : 4
                )}
              />
            )}

            {!!popularContentData?.popularContent && (
              <ContentVerticalList
                list={popularContentData.popularContent}
                title="Populaire artikelen"
                length={7}
              />
            )}
          </div>

          <div className="home-page-content-loaded">
            {loadedList && (
              <ContentFeed list={loadedList} fetchMore={fetchMore} />
            )}
          </div>

          <div className="btn-load-more">
            <Button
              label="Laad meer"
              Icon={ChevronDown}
              onClick={() => {
                if (contentLoading) return;

                setContentLoading(true);

                setLoadedList(
                  recentContentData.recentContent.slice(isMobile ? 6 : 4)
                );

                fetchMore({
                  variables: {
                    limit: 6,
                    offset: recentContentData.recentContent.length
                  },
                  updateQuery: (prev, { fetchMoreResult }) => ({
                    recentContent: [
                      ...prev.recentContent,
                      ...fetchMoreResult.recentContent
                    ]
                  })
                }).then(() => setContentLoading(false));
              }}
            />
          </div>

          <LazyLoadComponent>
            <Calendar />

            <TopicList useQuery={useQuery} />

            {!user.boughtCourses && <CoursesBox />}

            <CommunityBox
              user={user}
              config={config}
              list={userGroupsData?.userGroups}
              memberCount={
                userGroupsData?.userGroups.reduce((set, group) => {
                  group.members.forEach((member) => set.add(member.username));

                  return set;
                }, new Set()).size
              }
            />

            {!user.username && (
              <LoginBox
                loginUrl={config.LOGIN_URL}
                signupUrl={config.SIGNUP_URL}
              />
            )}
          </LazyLoadComponent>
        </>
      )}
    </>
  );
}
